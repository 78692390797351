// Replacement layout framework for the legacy bootstrap framework
// This provides many nore layout options

// layout types

.user_mode {
  //&#gridTest {
  .layoutContainer:has(.layoutContainer-ct-wrap) {
    display: grid;
    grid-template-columns: 1fr;
    place-items: center;
    align-items: stretch;
    width: 100%;

    .layoutContainer-ct-wrap {
      display: grid;
      place-items: center;
      align-items: stretch;
      width: 100%;
      .column {
        width: 100%;
      }
    }
    &.layout-100,
    &.layout-50-50,
    &.layout-33-66,
    &.layout-66-33,
    &.layout-50-25-25,
    &.layout-25-50-25,
    &.layout-25-25-50 {
      .layoutContainer-ct-wrap {
        grid-template-columns: 100%;
      }
    }
  }

  @media (min-width: 1024px) {
    .layoutContainer:has(.layoutContainer-ct-wrap) {
      display: grid;
      grid-template-columns: 1fr;
      place-items: center;
      align-items: stretch;
      width: 100%;

      .layoutContainer-ct-wrap {
        display: grid;
        place-items: center;
        align-items: stretch;
        width: 100%;
        .column {
          width: 100%;
        }
      }

      &.layout-100 {
        .layoutContainer-ct-wrap {
          grid-template-columns: 1fr;
        }
      }

      &.layout-50-50 {
        .layoutContainer-ct-wrap {
          grid-template-columns: 1fr 1fr;
        }
      }

      &.layout-33-66 {
        .layoutContainer-ct-wrap {
          grid-template-columns: 1fr 2fr;
        }
      }
      &.layout-66-33 {
        .layoutContainer-ct-wrap {
          grid-template-columns: 2fr 1fr;
        }
      }

      &.layout-33-33-33 {
        .layoutContainer-ct-wrap {
          grid-template-columns: 1fr 1fr 1fr;
        }
      }

      &.layout-50-25-25 {
        .layoutContainer-ct-wrap {
          grid-template-columns: 2fr 1fr 1fr;
        }
      }

      &.layout-25-50-25 {
        .layoutContainer-ct-wrap {
          grid-template-columns: 1fr 2fr 1fr;
        }
      }

      &.layout-25-25-50 {
        .layoutContainer-ct-wrap {
          grid-template-columns: 1fr 1fr 2fr;
        }
      }
    }
  }
  //}
}

// Content inside columns alignment | default is top aligned // Added center and bottom options
.user_mode {
  .layoutContainer:has(.layoutContainer-ct-wrap) {
    &.col-al-ctr {
      .layoutContainer-ct-wrap {
        .column {
          align-content: center;
        }
      }
    }
    &.col-al-bot {
      .layoutContainer-ct-wrap {
        .column {
          align-content: flex-end;
        }
      }
    }
  }
}

// Multi column options
// These classes only create multi columns for page elements in a Single column layout
// Does not work using presets for 2 or 3 columns
// Does not support mulitiple page elements in the same column

// User mode content structure is different than edit_mode structure
.user_mode .layoutContainer.layout-100:has(.layoutContainer-ct-wrap) {
  &.mcol-4,
  &.mcol-5,
  &.mcol-6,
  &.mcol-8,
  &.mcol-10 {
    .column {
      display: grid;
      grid-template-columns: 1fr;
      gap: var(--multi-column-gap);
      .pageElement {
        margin: 0;
      }
    }
  }

  &.mcol-grid-xs,
  &.mcol-grid-sm,
  &.mcol-grid-md,
  &.mcol-grid-lg,
  &.mcol-grid-xl {
    .column {
      display: flex;
      flex-flow: row wrap;
      gap: var(--multi-column-gap);
      justify-content: center;
      .pageElement {
        margin: 0;
      }
    }
  }

  &.mcol-grid-xs,
  &.mcol-grid-sm {
    flex-flow: row wrap;
    .pageEl {
      width: calc(100% / 3 - var(--multi-column-gap));
    }
  }
  &.mcol-grid-md,
  &.mcol-grid-lg {
    flex-flow: row wrap;
    .pageEl {
      width: calc(100% / 2 - var(--multi-column-gap));
    }
  }
  &.mcol-grid-xl {
    flex-flow: column wrap;
    .pageEl {
      width: calc(100% / 1 - var(--multi-column-gap));
    }
  }

  @media (min-width: 768px) {
    &.mcol-4 {
      .column {
        grid-template-columns: repeat(2, 1fr);
        & > .pageEl:nth-child(n + 5) {
          display: none;
        }
      }
    }
    // &.mcol-5 {
    //   .column {
    //     grid-template-columns: repeat(5, 1fr);
    //     & > .pageEl:nth-child(n + 65) {
    //       display: none;
    //     }
    //   }
    // }
    &.mcol-6 {
      .column {
        grid-template-columns: repeat(3, 1fr);
        & > .pageEl:nth-child(n + 7) {
          display: none;
        }
      }
    }
    &.mcol-8 {
      .column {
        grid-template-columns: repeat(4, 1fr);
        & > .pageEl:nth-child(n + 9) {
          display: none;
        }
      }
    }
    &.mcol-10 {
      .column {
        grid-template-columns: repeat(5, 1fr);
        & > .pageEl:nth-child(n + 11) {
          display: none;
        }
      }
    }
    &.mcol-grid-xs {
      .pageEl {
        width: calc(100% / 4 - var(--multi-column-gap));
      }
    }
    &.mcol-grid-sm {
      .pageEl {
        width: calc(100% / 4 - var(--multi-column-gap));
      }
    }
    &.mcol-grid-md {
      .pageEl {
        width: calc(100% / 2 - var(--multi-column-gap));
      }
    }
    &.mcol-grid-lg {
      .pageEl {
        width: calc(100% / 2 - var(--multi-column-gap));
      }
    }
    &.mcol-grid-xl {
      .pageEl {
        width: calc(100% / 2 - var(--multi-column-gap));
      }
    }
  }

  @media (min-width: 1024px) {
    &.mcol-4 {
      .column {
        grid-template-columns: repeat(4, 1fr);
      }
    }
    &.mcol-5 {
      .column {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    &.mcol-6 {
      .column {
        grid-template-columns: repeat(6, 1fr);
      }
    }
    &.mcol-8 {
      .column {
        grid-template-columns: repeat(8, 1fr);
      }
    }
    &.mcol-10 {
      .column {
        grid-template-columns: repeat(10, 1fr);
      }
    }
    &.mcol-grid-xs {
      .pageEl {
        width: var(--mcol-grid-xs);
      }
    }
    &.mcol-grid-sm {
      .pageEl {
        width: var(--mcol-grid-sm);
      }
    }
    &.mcol-grid-md {
      .pageEl {
        width: var(--mcol-grid-md);
      }
    }
    &.mcol-grid-lg {
      .pageEl {
        width: var(--mcol-grid-lg);
      }
    }
    &.mcol-grid-xl {
      .pageEl {
        width: var(--mcol-grid-xl);
      }
    }
  }
}

// Edit mode content structure is different than user_mode structure
.edit_mode .layoutContainer {
  &.mcol-4 {
    .columnBounds {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      & > .drag:nth-child(n + 6) {
        opacity: 0.5;
      }
      a.addPageElementTop {
        grid-column: 1 / 5;
        justify-self: center;
        text-align: center;
      }
    }
  }
  &.mcol-5 {
    .columnBounds {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      //gap: var(--multi-column-gap);
      a.addPageElementTop {
        grid-column: 1 / 6;
        justify-self: center;
        text-align: center;
      }
    }
  }
  &.mcol-6 {
    .columnBounds {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      //gap: var(--multi-column-gap);
    }
    a.addPageElementTop {
      grid-column: 1 / 7;
      justify-self: center;
      text-align: center;
    }
  }
  &.mcol-8 {
    .columnBounds {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      //gap: var(--multi-column-gap);
    }
    a.addPageElementTop {
      grid-column: 1 / 9;
      justify-self: center;
      text-align: center;
    }
  }
  &.mcol-10 {
    .columnBounds {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      //gap: var(--multi-column-gap);
    }
    a.addPageElementTop {
      grid-column: 1 / 9;
      justify-self: center;
      text-align: center;
    }
  }
  &[class*='mcol-grid-'] {
    .columnBounds {
      display: flex;
      flex-flow: row wrap;
      gap: var(--multi-column-gap);
      justify-content: center;
      .pageEl {
        margin: 0;
      }

      a.addPageElementTop {
        width: calc(100% - var(--multi-column-gap));
        max-width: 100%;
        margin: 0 25%;
        //justify-self: center;
        //text-align: center;
      }
    }
  }

  &.mcol-grid-xs,
  &.mcol-grid-sm {
    .column .columnBounds > div.drag {
      max-width: 150px !important;
    }
  }
  &.mcol-grid-md {
    .column .columnBounds > div.drag {
      max-width: var(--mcol-grid-md) !important;
    }
  }
  &.mcol-grid-lg {
    .column .columnBounds > div.drag {
      max-width: var(--mcol-grid-lg) !important;
    }
  }
  &.mcol-grid-xl {
    .column .columnBounds > div.drag {
      max-width: var(--mcol-grid-xl) !important;
    }
  }
}

// Hover Effects
.user_mode .layoutContainer:has(.layoutContainer-ct-wrap) {
  &[class*='mcol-grid-'] .column,
  &[class*='mcol-mos-'] .column {
    //gap: 0!important;
    .heroPhotoElement img {
      position: relative;
      border-radius: var(--radius-lg);
      transition: transform var(--mcol-transition-speed), border var(--mcol-transition-speed),
        box-shadow var(--mcol-transition-speed);
      //outline: 10px solid rgba(255, 255, 255, 0);
      //box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0);
      box-shadow: 0 0px 0px 10px rgba(255, 255, 255, 0), 0 0 30px 0 rgba(0, 0, 0, 0);
      &:hover {
        transform: scale(1.3);
        //outline-color: rgba(255, 255, 255, 1);
        //box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.5);
        box-shadow: 0 0px 0px 10px rgba(255, 255, 255, 1), 0 0 30px 0 rgba(0, 0, 0, 1);
        z-index: 1000;
      }
    }
  }
}

// Mosaic style grid
// Mosiac style columns

.user_mode .layoutContainer.layout-100:has(.layoutContainer-ct-wrap) {
  &[class*='mcol-mos-'] {
    .layoutContainer-ct-wrap .column {
      column-count: 2;
      .pageEl,
      .pageElement {
        margin: 5px 0;
      }
    }
  }

  &.mcol-mos-sm {
    .layoutContainer-ct-wrap .column {
      column-count: 2;
    }
  }
  &.mcol-mos-md {
    .layoutContainer-ct-wrap .column {
      column-count: 2;
    }
  }
  &.mcol-msc-lg {
    .layoutContainer-ct-wrap .column {
      column-count: 2;
    }
  }
  @media (min-width: 768px) {
    &.mcol-mos-sm {
      .layoutContainer-ct-wrap .column {
        column-count: 3;
      }
    }
    &.mcol-mos-md {
      .layoutContainer-ct-wrap .column {
        column-count: 3;
      }
    }
    &.mcol-mos-lg {
      .layoutContainer-ct-wrap .column {
        column-count: 3;
      }
    }
  }
  @media (min-width: 1024px) {
    &.mcol-mos-sm {
      .layoutContainer-ct-wrap .column {
        column-count: 5;
      }
    }
    &.mcol-mos-md {
      .layoutContainer-ct-wrap .column {
        column-count: 4;
      }
    }
  }
}
.edit_mode .layoutContainer.layout-100 {
  &[class*='mcol-mos-'] {
    .column .columnBounds {
      a.addPageElementTop {
        width: calc(100% - var(--multi-column-gap));
        max-width: 100%;
        margin: 10px 25%;
      }
    }
  }
  &.mcol-mos-sm {
    .column .columnBounds {
      //column-count: 5;
      display: flex;
      flex-flow: row wrap;
      div.drag {
        max-width: calc(100% / 5);
      }
    }
  }
  &.mcol-mos-md {
    .column .columnBounds {
      //column-count: 5;
      display: flex;
      flex-flow: row wrap;
      div.drag {
        max-width: calc(100% / 4);
      }
    }
  }
  &.mcol-mos-lg {
    .column .columnBounds {
      //column-count: 5;
      display: flex;
      flex-flow: row wrap;
      div.drag {
        max-width: calc(100% / 3);
      }
    }
  }
}
