.user_mode .layoutContainer.video-bg {
  display: block;
  position: relative;
  background-color: black;
  overflow: hidden;
  //aspect-ratio: 16 /9;

  .layoutContainer-ct-wrap .column {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    aspect-ratio: 16/9;
  }

  .pageEl {
    z-index: 1;
  }

  .pageEl.video-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 0;

    .pageElement.codeElement {
      margin: 0 !important;
    }
  }

  &.ar-4-3 {
    .layoutContainer-ct-wrap .column {
      aspect-ratio: 4 / 3;
    }
  }
  &.ar-16-9 {
    .layoutContainer-ct-wrap .column {
      aspect-ratio: 16 / 9;
    }
  }
  &.ar-21-9 {
    .layoutContainer-ct-wrap .column {
      aspect-ratio: 21 / 9;
    }
  }
  &.ar-3-1 {
    .layoutContainer-ct-wrap .column {
      aspect-ratio: 3 / 1;
    }
  }
}
