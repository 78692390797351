// Color overlays for images and CTAs

.pageEl[class*='img-ovl-clr-'] {
    .pageElement{
        position: relative;
    }
  .pageElement:before,
  .sn-call-to-action:before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    mix-blend-mode: color;
  }
  .sn-call-to-action.sn-variation-slide-up .sn-call-to-action-overlay {
    z-index: 10;
  }
}

.pageEl.img-ovl-clr-al {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-al);
  }
}
.pageEl.img-ovl-clr-a {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-a);
  }
}
.pageEl.img-ovl-clr-ad {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-ad);
  }
}
.pageEl.img-ovl-clr-bl {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-b);
  }
}
.pageEl.img-ovl-clr-b {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-b);
  }
}
.pageEl.img-ovl-clr-bd {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-b);
  }
}
.pageEl.img-ovl-clr-cl {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-c);
  }
}
.pageEl.img-ovl-clr-c {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-c);
  }
}
.pageEl.img-ovl-clr-cd {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-c);
  }
}
.pageEl.img-ovl-clr-dl {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-d);
  }
}
.pageEl.img-ovl-clr-d {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-d);
  }
}
.pageEl.img-ovl-clr-dd {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-d);
  }
}
.pageEl.img-ovl-clr-black {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-black);
  }
}
.pageEl.img-ovl-clr-white {
  .pageElement:before,
  .sn-call-to-action:before {
    background-color: var(--site-clr-white);
  }
}
