// Replacement layout framework for the legacy bootstrap framework
// This provides many nore layout options

// layout types

.user_mode {
  //&#gridTest {
    .layoutContainer:has(.layoutContainer-ct-wrap) {
      display: grid;
      grid-template-columns: 1fr;
      place-items: center;
      align-items: stretch;
      width: 100%;

      .layoutContainer-ct-wrap {
        display: grid;
        place-items: center;
        align-items: stretch;
        width: 100%;
        .column {
          width: 100%;
        }
      }
      &.layout-100,
      &.layout-50-50,
      &.layout-33-66,
      &.layout-66-33,
      &.layout-50-25-25,
      &.layout-25-50-25,
      &.layout-25-25-50 {
        .layoutContainer-ct-wrap {
          grid-template-columns: 100%;
        }
      }
    }

    @media (min-width: 1024px) {
      .layoutContainer:has(.layoutContainer-ct-wrap) {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
        align-items: stretch;
        width: 100%;

        .layoutContainer-ct-wrap {
          display: grid;
          place-items: center;
          align-items: stretch;
          width: 100%;
          .column {
            width: 100%;
          }
        }

        &.layout-100 {
          .layoutContainer-ct-wrap {
            grid-template-columns: 1fr;
          }
        }

        &.layout-50-50 {
          .layoutContainer-ct-wrap {
            grid-template-columns: 1fr 1fr;
          }
        }

        &.layout-33-66 {
          .layoutContainer-ct-wrap {
            grid-template-columns: 1fr 2fr;
          }
        }
        &.layout-66-33 {
          .layoutContainer-ct-wrap {
            grid-template-columns: 2fr 1fr;
          }
        }

        &.layout-33-33-33 {
          .layoutContainer-ct-wrap {
            grid-template-columns: 1fr 1fr 1fr;
          }
        }

        &.layout-50-25-25 {
          .layoutContainer-ct-wrap {
            grid-template-columns: 2fr 1fr 1fr;
          }
        }

        &.layout-25-50-25 {
          .layoutContainer-ct-wrap {
            grid-template-columns: 1fr 2fr 1fr;
          }
        }

        &.layout-25-25-50 {
          .layoutContainer-ct-wrap {
            grid-template-columns: 1fr 1fr 2fr;
          }
        }
      }
    }
  //}
}

// Content inside columns alignment | default is top aligned // Added center and bottom options
.user_mode {
  .layoutContainer:has(.layoutContainer-ct-wrap) {
    &.col-al-ctr {
      .layoutContainer-ct-wrap {
        .column {
          align-content: center;
        }
      }
    }
    &.col-al-bot {
      .layoutContainer-ct-wrap {
        .column {
          align-content: flex-end;
        }
      }
    }
  }
}

// Multi column options
// These classes only create multi columns for page elements in a Single column layout
// Does not work using presets for 2 or 3 columns
// Does not support mulitiple page elements in the same column

// User mode content structure is different than edit_mode structure
.user_mode .layoutContainer {
  &.mcol-4, &.mcol-5, &.mcol-6 , &.mcol-8 , &.mcol-10  {
    .column {
      display: grid;
      grid-template-columns: 1fr;
      gap: var(--multi-column-gap);
    }
  }

  @media (min-width: 768px) {
    &.mcol-4 {
      .column {
        grid-template-columns: repeat(2, 1fr);
        & > .pageEl:nth-child(n + 5) {
          display: none; 
        }
      }
    }
    // &.mcol-5 {
    //   .column {
    //     grid-template-columns: repeat(5, 1fr);
    //     & > .pageEl:nth-child(n + 65) {
    //       display: none; 
    //     }
    //   }
    // }
    &.mcol-6 {
      .column {
        grid-template-columns: repeat(3, 1fr);
        & > .pageEl:nth-child(n + 7) {
          display: none; 
        }
      }
    }
    &.mcol-8 {
      .column {
        grid-template-columns: repeat(4, 1fr);
        & > .pageEl:nth-child(n + 9) {
          display: none; 
        }
      }
    }
    &.mcol-10 {
      .column {
        grid-template-columns: repeat(5, 1fr);
        & > .pageEl:nth-child(n + 11) {
          display: none; 
        }
      }
    }
  }
  @media (min-width: 1024px) {
    &.mcol-4 {
      .column {
        grid-template-columns: repeat(4, 1fr);
      }
    }
    &.mcol-5 {
      .column {
        grid-template-columns: repeat(5, 1fr);
      }
    }
    &.mcol-6 {
      .column {
        grid-template-columns: repeat(6, 1fr);
      }
    }
    &.mcol-8 {
      .column {
        grid-template-columns: repeat(8, 1fr);
      }
    }
    &.mcol-10 {
      .column {
        grid-template-columns: repeat(10, 1fr);
      }
    }
  }
}

// Edit mode content structure is different than user_mode structure
.edit_mode .layoutContainer {
  &.mcol-4 {
    .columnBounds {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      & > .drag:nth-child(n + 6) {
        opacity: .5; 
      }
      a.addPageElementTop {
        grid-column: 1 / 5;
        justify-self: center;
        text-align: center;
      }
    }
  }
  &.mcol-5 {
    .columnBounds {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      //gap: var(--multi-column-gap);
      a.addPageElementTop {
        grid-column: 1 / 6;
        justify-self: center;
        text-align: center;
      }
    }
  }
  &.mcol-6 {
    .columnBounds {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      //gap: var(--multi-column-gap);
    }
    a.addPageElementTop {
      grid-column: 1 / 7;
      justify-self: center;
      text-align: center;
    }
  }
  &.mcol-8 {
    .columnBounds {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      //gap: var(--multi-column-gap);
    }
    a.addPageElementTop {
      grid-column: 1 / 9;
      justify-self: center;
      text-align: center;
    }
  }
  &.mcol-10 {
    .columnBounds {
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      //gap: var(--multi-column-gap);
    }
    a.addPageElementTop {
      grid-column: 1 / 11;
      justify-self: center;
      text-align: center;
    }
  }
  &.mcol-var {
    .columnBounds {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      //gap: var(--multi-column-gap);
    }
    a.addPageElementTop {
      grid-column: 1 / 12;
      justify-self: center;
      text-align: center;
    }
  }
}
