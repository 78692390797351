// Fixes issues with the premier theme

body.edit_mode a[href*='edit_header_code'] {
  display: inline;
}

// CTA Image Size Fix
// This fixes images in the CTA element that do not display at the correct size

.sn-call-to-action {
  height: unset !important;
  padding: 0;
  img {
    width: 100% !important;
    height: auto !important;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    width: auto !important;
    max-width: 100% !important;
    min-width: 100% !important;
    -ms-transform: unset !important;
    transform: unset !important;
  }

  h4.sn-call-to-action-title,
  p.sn-call-to-action-subtitle {
    text-shadow: 0 0 7px rgba(0, 0, 0, 0.7);
  }
  // Restores the hover effect for image zoom
  &.sn-variation-zoom:hover img {
    transform: scale(1.1) !important;
  }
}
