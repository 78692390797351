// Image Background
// Handles displaying a photo element as the background for different container types
  
// Reduce bg image size in edit mode to make editing more convenient
.edit_mode .layoutContainer.image-bg .image-bg img {
  max-width: 300px;
}


// Hide source image
.user_mode .layoutContainer.image-bg .image-bg {
  display: none;
}

.user_mode .layoutContainer.image-bg {
  overflow: hidden;
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center; 
  &.image-al-lf {
    background-position: left;
  }
  &.image-al-rt{
    background-position: right;
  }
  &.image-al-tp {
    background-position: top;
  }
  &.image-al-bt {
    background-position: bottom;
  }
  &.image-al-plx {
    background-attachment: fixed;
  }

}