// Useful for creating a visible gap between columns which makes them look like a card style
// Requires the layount container background color to be non-white,
// the column background color will be white

.user_mode {
  .layoutContainer.col-gap {
    .layoutContainer-ct-wrap {
      column-gap: var(--split-column-gap);

      .column {
        background-color: white;
      }
    }
  }
}
