.pageEl.postcard .heroPhotoElement {
  display: grid;
  place-items: center;
  background-color: black;
  a {
    width: 100%;
  }
  p.cutline {
    position: absolute;
    background: none;
    top: 0;
    left: 0;
    width: 100%;
    display: grid;
    place-items: center;
    font-size: 48px;
    font-weight: bold;
    color: white;
    font-style: normal;
    text-shadow: 0 0 5px black;
    pointer-events: none;
  }
  img{
    transition: transform 1s ease, opacity 1s ease;
  }
  &:hover img{ 
    transform: scale(1.1);
    opacity: 0.8; 
  }
}
