:root {
  --editor-primary-color: #282f39;
  --editor-primary-color-lt: #9aa4b0;
  --editor-primary-color-xlt: #cfd1d4;
  --editor-secondary-color: #46874a;
  --editor-button-color: #60b565;
  --editor-border-color: #acacac;
  --editor-container-bg-color: #e3e7eb;
}

.edit_mode {
  font-family: sans-serif;

  .contentTabs.rapidTabsConstruction li span a {
    font-family: sans-serif;
  }
  .fcForm input[type='text'] {
    width: 100%;
  }
  .fcForm input[type='text'].tiny {
    width: 30px;
  }
  .fcForm input:not([type='button']).small {
    width: 25%;
  }
  .contentTabs.rapidTabsConstruction .selected span a {
    color: #ffffff;
  }
  .fa-gear:before,
  .fa-cog:before {
    color: #ffffff;
  }
  .rapidTabsConstruction {
    background: var(--editor-primary-color);
    border-top: solid 1px var(--editor-border-color);
  }
  .rapidContentConstruction {
    background: none;
    background-color: var(--editor-primary-color-xlt);
    border-bottom: solid 1px var(--editor-primary-color-lt);
    a {
      color: var(--editor-primary-color);
    }
  }

  .pageElementMenu {
    background: var(--editor-primary-color);
    border-bottom: solid 1px var(--editor-border-color);
    border-top: solid 1px var(--editor-border-color);
  }

  .dragTwo .containerBar .handle,
  .lock .containerBar .handle {
    color: white;
  }

  .rapidTabsConstruction li {
    border-right: solid 1px var(--editor-border-color);
  }

  .contentTabs.rapidTabsConstruction li span a {
    color: white;
  }
  .pageElementMenu {
    color: white;
    a {
      color: white;
    }
  }

  .layoutContainer.image-bg {
    background-color: var(--editor-container-bg-color);
    background-blend-mode: multiply;
  }

  .layoutContainer .textBlockElement {
    text-shadow: 0 0 5px #0000004d;
  }
}
.containerBar {
  display: flex;
  width: 100%;
  height: 25px;
  background: none;
  background-color: var(--editor-primary-color-lt);
  text-transform: uppercase;
  font-size: 11px;
  margin: 0;
  align-items: center;
}
.fa-gear:before,
.fa-cog:before {
  color: #ccc;
}

.pageElementAdmin .elementBar,
.photoGalleryThumb .elementBar,
.galleryThumbAdmin .elementBar,
.clientGraphic .elementBar {
  font-size: 12px;
  background: none;
  background-color: var(--editor-primary-color-lt);
}
.elementBar > .handle {
  color: #ffffff;
}

.addPageElementTop {
  background: none;
  background-color: var(--editor-button-color);
  max-width: 45%;
  margin: 10px auto;
  border-radius: 3px;
  font-size: 12px;
  font-weight: normal;
  padding: 0 15px;
  white-space: nowrap;
}

.layoutContainerAdmin .columnBounds {
  border: dashed 1px #bababa;
}

.layoutContainerAdmin .layoutContainer {
  border: solid 2px var(--editor-primary-color-lt);
  padding: 3px;
}

.contentTabs li:hover,
.contentTabs li.selected {
  background: var(--editor-primary-color-lt) !important;
}

.edit_mode .contentTabs.rapidTabsConstruction .selected span a {
  background: none;
}

.inputForm textarea,
.fcForm textarea,
.coreContainer form table textarea {
  width: 100%;
}

input#custom_css_class {
  width: 100%;
}

div#cke_textedit {
  width: 100%;
}

.fcForm.textarea {
  width: 100%;
}

.dragTwo .handle {
  color: white;
  display: inline-block;
}

.dragTwo .containerBar .handle,
.lock .containerBar .handle {
  padding-left: 5px;
}

.dragTwo .handle:before {
  font-family: fontawesome;
  content: '\f047';
  display: inline-block;
  left: 0;
  border-right: 5px solid transparent;
}

.drag .handle {
  color: #ffffff;
  display: inline-block;
}

.drag .handle:before {
  font-family: fontawesome;
  content: '\f047';
  display: inline-block;
  left: 5px;
  top: -1px;
  position: absolute;
}

.addPageElement {
  filter: grayscale(100%); // Convert image to grayscale
}

a[id^='addPE_']::after {
  content: '\f067';
  font-family: fontawesome;
  margin-left: 5px;
}

a[id^='addPE_'] {
  color: #123914;
  border-radius: 10px;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    color: white;
    background: none;
    background-color: #439147;
    filter: grayscale(0);
  }
}

// Lightbox

.edit_mode {
  .helperLightbox #lightboxHeader {
    background: #000000;
  }
  #lightbox {
    width: 90%;
    max-width: 1500px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-top: 1px solid #888;
    overflow: hidden;
  }

  #lightboxFooter {
    border-top: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: none;
    background-color: var(--editor-primary-color);
    border-bottom: 1px solid #888;

    #lightboxFeedback {
      background: none;
      background-color: var(--editor-primary-color);
    }
  }

  #lightboxHeader h1 {
    background-size: cover;
  }

  #lightboxHeader a {
    background-size: cover;
    &:hover {
      background-color: var(--editor-primary-color-lt);
    }
  }

  div#lightboxHeader {
    border: 0 !important;
    h1,
    a {
      color: white;
      background: var(--editor-primary-color);
    }
    a {
      border-left-color: var(--editor-primary-color-light);
    }
  }

  #lightboxBody {
    background: #f2f2f2;
    color: #333;
    h3 {
      color: var(--editor-primary-color);
    }
    p {
      font-size: 0.9em;
    }
    a {
      color: var(--editor-primary-color);
    }
  }

  #lightboxBody .elementMenuOption {
    border-bottom: solid 1px var(--editor-primary-color-lt);
  }

  .selectElementList {
    border-top: solid 1px var(--editor-primary-color-lt);
  }

  .selectElementList li a:hover {
    background-color: var(--editor-primary-color-lt);
    text-decoration: none;
  }
  .submitLightbox {
    border: 0;
    background-color: var(--editor-primary-color-xlt);
  }

  .rapidContentConstruction .noEntry,
  .constructionLightbox .noEntry {
    color: var(--editor-primary-color);
    background: var(--editor-primary-color-lt);
    border-top: solid 1px var(--editor-primary-color-md);
  }

  .fcForm {
    .radio-tab-content,
    .radio-tab-selected {
      background: var(--editor-primary-color-xlt);
      border-color: var(--editor-primary-color-lt);
    }
  }

  .elementMenuOption {
    background-repeat: no-repeat;
    filter: grayscale(80%);
    background-position: 5px 12px;
    padding: 10px 5px 5px 55px;
    border-left: 5px solid transparent !important;
    border-right: 15px solid transparent !important;
    border-bottom: 5px solid transparent !important;
    width: 100%;
    overflow: hidden !important;
    border-radius: 10px;
    transition: background-color 0.3s;
    p {
      margin-bottom: 0 !important;
    }
  }

  ul.selectElementList {
    border-top: 0 !important;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates 3 equal columns */
    gap: 5px;
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      height: 100%;
      background-color: #ddd;
      padding: 1px;
      border-radius: 10px;
    }
  }

  // Tables
  .rapidContentConstruction .dataTable,
  .rapidContentConstruction .resultsTable,
  #profiles_table {
    border: solid 1px #9aa4b0;
  }
  .rapidContentConstruction .odd {
    background-color: #eaeaea;
  }
  .rapidContentConstruction .even {
    background-color: #ffffff;
  }
  .rapidContentConstruction table {
    td,
    th {
      padding: 2px 4px;
    }
  }

  // Edit head code modal - add max character warning
  label[for='page_node_header_code']:after {
    content: 'Important: max-size is 4000 characters. Overflow and invalid code may cause issues';
    color: gray;
    font-weight: normal;
    display: block;
    width: 100%;
    height: 20px;
    top: 0;
    left: 0;
  }

  // Custom CSS Class input - add max character warning
  label[for='custom_css_class']:after {
    content: 'Important: max-size is 255 characters. Overflow is truncated';
    color: gray;
    font-weight: normal;
    display: block;
    width: 100%;
    height: 20px;
    top: 0;
    left: 0;
  }

  // Code element title - add max character warning
  div.elementMenuOption[style*='background-image: url(/app_images/peIconCode.gif)'] > p:first-of-type:after {
    content: ' Important warning: max-size is 1990 characters. Overflow and invalid code may cause issues. Be sure to validate code before saving.';
    font-weight: bold;
  }
}

// Notifications

#flashNotice {
  border: 1px solid var(--editor-primary-color-lt);
  border-radius: 25px;
  background-color: var(--editor-primary-color-xlt);
  h2 {
    color: var(--editor-primary-color);
  }
}
