// Background classes to add color options to layoutcontainers and elements

// BG Colors
.layoutContainer,
.pageEl {
  &.bg-clr-black {
    background-color: var(--site-clr-black);
  }
  &.bg-clr-white {
    background-color: var(--site-clr-white);
  }
  &.bg-clr-gray-xl {
    background-color: var(--site-clr-gray-xl);
  }
  &.bg-clr-gray-lt {
    background-color: var(--site-clr-gray-lt);
  }
  &.bg-clr-gray {
    background-color: var(--site-clr-gray);
  }
  &.bg-clr-gray-dk {
    background-color: var(--site-clr-gray-dk);
  }
  &.bg-clr-gray-xd {
    background-color: var(--site-clr-gray-xd);
  }
  &.bg-clr-a {
    background-color: var(--site-clr-a);
  }
  &.bg-clr-b {
    background-color: var(--site-clr-b);
  }
  &.bg-clr-c {
    background-color: var(--site-clr-c);
  }
  &.bg-clr-d {
    background-color: var(--site-clr-d);
  }
  &.bg-clr-prm-bg {
    background-color: var(--site-clr-prm-bg);
  }
}

// BG Overlays to darken or lighten the the background image or color
.bg-ovl-dd {
  background-color: var(--image-bg-ovl-dd);
  background-blend-mode: multiply;
}
.bg-ovl-dm {
  background-color: var(--image-bg-ovl-dm);
  background-blend-mode: multiply;
}
.bg-ovl-dl {
  background-color: var(--image-bg-ovl-dl);
  background-blend-mode: multiply;
}

.bg-ovl-ll {
  background-color: var(--image-bg-ovl-ll);
  background-blend-mode: screen;
}
.bg-ovl-lm {
  background-color: var(--image-bg-ovl-lm);
  background-blend-mode: screen;
}
.bg-ovl-lx {
  background-color: var(--image-bg-ovl-lx);
  background-blend-mode: screen;
}

.bg-grd-tb {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: var(--gradient-top-bottom);
    background-size: cover;
    background-blend-mode: multiply;
  }
}
.bg-grd-bt {
  position: relative;

  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: var(--gradient-bottom-top);
    background-size: cover;
    background-blend-mode: multiply;
  }
}
// Colorizing background images
.layoutContainer.bg-img-clr-a {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-color: var(--site-clr-a);
    mix-blend-mode: color;
  }
}
.layoutContainer.bg-img-clr-a {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-color: var(--site-clr-a);
    mix-blend-mode: color;
  }
}
.layoutContainer.bg-img-clr-b {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-color: var(--site-clr-b);
    mix-blend-mode: color;
  }
}
.layoutContainer.bg-img-clr-c {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-color: var(--site-clr-c);
    mix-blend-mode: color;
  }
}
.layoutContainer.bg-img-clr-d {
  position: relative;
  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-color: var(--site-clr-d);
    mix-blend-mode: color;
  }
}
