// Fixes issues with the premier theme

// Renable the edit header code link in page settings
body.edit_mode a[href*='edit_header_code'] {
  display: inline;
}

// Removed margin from the top container
#siteContainer #yieldContent.row {
  margin: 0;
}

// CTA Element Image Size Fix
// This fixes images in the CTA element that do not display at the correct size
.sn-call-to-action {
  height: unset !important;
  padding: 0;
  img {
    width: 100% !important;
    height: auto !important;
    position: relative !important;
    top: unset !important;
    left: unset !important;
    width: auto !important;
    max-width: 100% !important;
    min-width: 100% !important;
    -ms-transform: unset !important;
    transform: unset !important;
  }

  h4.sn-call-to-action-title,
  p.sn-call-to-action-subtitle {
    text-shadow: 0 0 7px rgba(0, 0, 0, 0.7);
  }
  // Restores the hover effect for image zoom
  &.sn-variation-zoom:hover img {
    transform: scale(1.1) !important;
  }
}

// Premier Theme - Media & CTA Home page fix for the Hero/CTA section: option 3
// Additions addon overrides some of the necessary styles for this section
// This code restores those styles
.homeCallToActions.layout-2 .layoutContainer-ct-wrap {
  position: absolute;
  width: calc(100% - 45% - 50px) !important;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  top: 0;
  right: 50px;
  padding: 0 calc(((100% - 45%) - 45%) / 2);
  background: transparent;
}

@media screen and (max-width: 690px) {
  .homeCallToActions.layout-2 .layoutContainer-ct-wrap {
    display: flex !important;
    position: relative;
    width: 100% !important;
    padding: 0;
    right: 0;
  }
}

// Fixes an issue with the footer logo not not being vertically centered in layout-2
// and updates the footer container to use the grid framework
.layoutContainer.layout-20-20-20-20-20 {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  .column {
    display: grid;
    width: unset!important;

    &:first-of-type {
      // center - logo
      place-items: center;
    }
    &:last-of-type {
      // horizontally center - cta
      justify-content: center;
    }
  }
}

@media (min-width: 1024px) {
  .layoutContainer.layout-20-20-20-20-20 {
    grid-template-columns: repeat(5, 1fr);
  }
}

// Add hover effect to call to action buttons, footer logo
#topNav:not(.layout-3) #sn-site-logo a,
.headerCallToAction a,
.footerCallToAction a,
.footerLogo img,
.sn-social-media-list a {
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }
}


// Home page has split containers
// These are removed by default, but htis will add them back if needed
.user_mode.home .yieldPageContent > .layoutContainer > .layoutContainer-ct-wrap > .column {
  background-color: var(--home-split-ct-bg);
  border: var(--home-split-ct-gap) solid #f1f1f1;
  margin-top: var(--home-split-ct-gap);
}

