// Border features like rounded corners

// :root {
//   --radius-sm: 3px;
//   --radius-md: 5px;
//   --radius-lg: 10px;
//   --radius-xl: 20px;
// }

// Hero photo elements
.pageEl {
  &.round-sm {
    .heroPhotoElement img {
      border-radius: var(--radius-sm);
    }
  }
  &.round-md {
    .heroPhotoElement img {
      border-radius: var(--radius-md);
    }
  }
  &.round-lg {
    .heroPhotoElement img {
      border-radius: var(--radius-lg);
    }
  }
  &.round-md {
    .heroPhotoElement img {
      border-radius: var(--radius-md);
    }
  }
  &.round-xl {
    .heroPhotoElement img {
      border-radius: var(--radius-xl);
    }
  }
}

// Link Element default
.pageEl {
  &.round-sm {
    .linkElement h4 a  {
      border-radius: var(--radius-sm);
      overflow:hidden;
    }
  }
  &.round-md {
    .linkElement h4 a  {
      border-radius: var(--radius-md);
      overflow:hidden;
    }
  }
}


// Link Elements with styles - Not the default link element
.pageEl {
  &.round-sm {
    .linkElement h4  {
      border-radius: var(--radius-sm);
      overflow:hidden;
    }
  }
  &.round-md {
    .linkElement h4  {
      border-radius: var(--radius-md);
      overflow:hidden;
    }
  }
  &.round-lg {
    .linkElement h4  {
      border-radius: var(--radius-lg);
      overflow:hidden;
    }
  }
  &.round-xl {
    .linkElement h4  {
      border-radius: var(--radius-xl);
      overflow:hidden;
    }
  }
  &.round-pill {
    .linkElement h4  {
      border-radius: var(--radius-pill);
      overflow:hidden;
    }
  }
}

// CTA Elements
.pageEl {
  &.round-sm {
    .sn-call-to-action {
      border-radius: var(--radius-sm);
      padding: 0;
    }
  }
  &.round-md {
    .sn-call-to-action {
      border-radius: var(--radius-md);
      padding: 0;
    }
  }
  &.round-lg {
    .sn-call-to-action {
      border-radius: var(--radius-lg);
      padding: 0;
    }
  }
  &.round-xl {
    .sn-call-to-action {
      border-radius: var(--radius-xl);
      padding: 0;
    }
  }
}

// Layout Ontainer
.layoutContainer {
  &.round-sm {
    border-radius: var(--radius-sm);
  }
  &.round-md {
    border-radius: var(--radius-md);
  }
  &.round-lg {
    border-radius: var(--radius-lg);
  }
  &.round-xl {
    border-radius: var(--radius-xl);
  }
}

// Remove CTA Borders
.sn-call-to-action  {
  &:before,
  &:after {
    display: none;
  }
  .sn-call-to-action-overlay {
    text-shadow: 0 0 5px black;
  }
}
