// Style option for text blocks

.pageEl {
  &.txt-shd-lt .textBlockElement .text {
    text-shadow: var(--txt-shd-lt);
  }
  &.txt-shd-md .textBlockElement .text {
    text-shadow: var(--txt-shd-md);
  }
  &.txt-shd-dk .textBlockElement .text {
    text-shadow: var(--txt-shd-dk);
  }
}
