// Message bar styles

.layoutContainer.msg-bar {
    .pageEl {
        display:inline;
        .textBlockElement {
            display:flex;
            flex-flow: row nowrap;
            padding: 0 10px;
            margin:0; 
            h3 { display:none; color: white; padding-right: 10px; margin:0; }
            .text { white-space: nowrap;margin:0; }
        }
        .linkElement {
           
            
        }
    }
    .column {
        display: flex;
        flex-flow: row wrap;
        justify-content:center;
        align-items: center;
    }
}