// Spacing classes for padding and margin

:root {
  --spacing-x: 0;
  --spacing-xs: 1px;        
  --spacing-sm: 0.3125rem; // 5px
  --spacing-md: 0.625rem;   // 10px
  --spacing-lg: 0.9375rem; // 15px
  --spacing-xl: 1.875rem; // 30px
  --spacing-2x: 3.75rem; // 60px
  --spacing-3x: 5.61rem; // 90px
}


    .layoutContainer, .pageEl {
        &.pd-x{
            padding: var(--spacing-x);
        }
        &.pd-xs{
            padding: var(--spacing-xs);
        }
        &.pd-sm{
            padding: var(--spacing-sm);
        }
        &.pd-md{
            padding: var(--spacing-md);
        }
        &.pd-lg{
            padding: var(--spacing-lg);
        }
        &.pd-xl{
            padding: var(--spacing-xl);
        }
        &.pd-2x{
            padding: var(--spacing-2x);
        }
        &.pd-3x{
            padding: var(--spacing-3x);
        }
        &.mg-x{
            margin: var(--spacing-x);
        }
        &.mg-xs{
            margin: var(--spacing-xs);
        }
        &.mg-sm{
            margin: var(--spacing-sm);
        }
        &.mg-md{
            margin: var(--spacing-md);
        }
        &.mg-lg{
            margin: var(--spacing-lg);
        }
        &.mg-xl{
            margin: var(--spacing-xl);
        }
        &.mg-2x{
            margin: var(--spacing-2x);
        }
        &.mg-3x{
            margin: var(--spacing-3x);
        }
        &.pd-v-x{
            padding-top: var(--spacing-x);
            padding-bottom: var(--spacing-x);
        }
        &.pd-v-sm{
            padding-top: var(--spacing-sm);
            padding-bottom: var(--spacing-sm);
        }
        &.pd-v-md{
            padding-top: var(--spacing-md);
            padding-bottom: var(--spacing-md);
        }
        &.pd-v-lg{
            padding-top: var(--spacing-lg);
            padding-bottom: var(--spacing-lg);
        }
        &.pd-v-xl{
            padding-top: var(--spacing-xl);
            padding-bottom: var(--spacing-xl);
        }
        &.pd-v-2x{
            padding-top: var(--spacing-2x);
            padding-bottom: var(--spacing-2x);
        }
        &.pd-v-3x{
            padding-top: var(--spacing-3x);
            padding-bottom: var(--spacing-3x);
        }
        &.pd-h-x{
            padding-left: var(--spacing-x);
            padding-right: var(--spacing-x);
        }
        &.pd-h-sm{
            padding-left: var(--spacing-sm);
            padding-right: var(--spacing-sm);
        }
        &.pd-h-md{
            padding-left: var(--spacing-md);
            padding-right: var(--spacing-md);
        }
        &.pd-h-lg{
            padding-left: var(--spacing-lg);
            padding-right: var(--spacing-lg);
        }
        &.pd-h-xl{
            padding-left: var(--spacing-xl);
            padding-right: var(--spacing-xl);
        }
        &.pd-h-2x{
            padding-left: var(--spacing-2x);
            padding-right: var(--spacing-2x);
        }
        &.pd-h-3x{
            padding-left: var(--spacing-3x);
            padding-right: var(--spacing-3x);
        }

        &.mg-v-x{
            margin-top: var(--spacing-x);
            margin-bottom: var(--spacing-x);
        }
        &.mg-v-xs{
            margin-top: var(--spacing-xs);
            margin-bottom: var(--spacing-xs);
        }
        &.mg-v-sm{
            margin-top: var(--spacing-sm);
            margin-bottom: var(--spacing-sm);
        }
        &.mg-v-md{
            margin-top: var(--spacing-md);
            margin-bottom: var(--spacing-md);
        }
        &.mg-v-lg{
            margin-top: var(--spacing-lg);
            margin-bottom: var(--spacing-lg);
        }
        &.mg-v-xl{
            margin-top: var(--spacing-xl);
            margin-bottom: var(--spacing-xl);
        }
        &.mg-v-2x{
            margin-top: var(--spacing-2x);
            margin-bottom: var(--spacing-2x);
        }
        &.mg-v-3x{
            margin-top: var(--spacing-3x);
            margin-bottom: var(--spacing-3x);
        }
        &.mg-h-x{
            margin-left: var(--spacing-x);
            margin-right: var(--spacing-x);
        }
        &.mg-h-sm{
            margin-left: var(--spacing-sm);
            margin-right: var(--spacing-sm);
        }
        &.mg-h-md{
            margin-left: var(--spacing-md);
            margin-right: var(--spacing-md);
        }
        &.mg-h-lg{
            margin-left: var(--spacing-lg);
            margin-right: var(--spacing-lg);
        }
        &.mg-h-xl{
            margin-left: var(--spacing-xl);
            margin-right: var(--spacing-xl);
        }
        &.mg-h-2x{
            margin-left: var(--spacing-2x);
            margin-right: var(--spacing-2x);
        }
        &.mg-h-3x{
            margin-left: var(--spacing-3x);
            margin-right: var(--spacing-3x);
        }

    }

    
