// Container styles to handle switching to full-width containers and content
// Hiding containers in mobile or desktop breakpoints

// Change the default page container to full-width
.user_mode {
  div#siteContainer {
    max-width: unset;
  }

  .layoutContainer.full-width {
    max-width: unset !important;
  }

  .layoutContainer.full-width.content-width {
    .layoutContainer-ct-wrap {
      max-width: var(--premier-content-width);
      margin: auto;
    }
  }

  // .layoutContainer-ct-wrap id added to container via a js function in layoutContinerContentWrap.js
  // This extra wrapper provides more styling options
  .layoutContainer:has(.layoutContainer-ct-wrap) {
    display: grid;
    align-content: center;
    &.content-v-top {
      align-content: start;
    }
    &.content-v-bt {
      align-content: end;
    }
  }
}

// These premier specific containers need some addional styles to display
// corrently with the new full-width change
.layoutContainer:not(.homeMediaSlider):not(.homeCallToActions):not(.sponsor-slider) {
  max-width: var(--premier-content-width);
  margin-left: auto;
  margin-right: auto;
}

.page-manager-visible .homeMediaSlider,
.page-manager-visible .homeCallToActions {
  margin-left: unset;
}

.homeMediaSlider,
.homeCallToActions {
  margin-left: unset;
}

// Hiding containers in mobile or desktop breakpoints

.user_mode {
  @media (max-width: 1024px) {
    .layoutContainer {
      &.hide-mbl {
        display: none;
      }
      .pageEl.hide-mbl {
        display: none;
      }
    }
  }

  @media (min-width: 1025px) {
    .layoutContainer {
      &.hide-dsk {
        display: none;
      }
      .pageEl.hide-dsk {
        display: none;
      }
    }
  }
}

.edit_mode {
  .layoutContainer.hide-mbl {
    border: 2px solid #ffd883;
  }
  .layoutContainer.hide-dsk {
    border: 2px solid #fe6969;
  }
}
