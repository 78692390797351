// Aspect Ratios

// Layout containers
.user_mode {
  .layoutContainer {

    // Square
    &.ar-sq {
      aspect-ratio: 1 / 1;
    }

    // Portait
    &.ar-4-3 {
      aspect-ratio: 4 / 3;
    }
    &.ar-16-9 {
      aspect-ratio: 16 / 9;
    }
    &.ar-21-9 {
      aspect-ratio: 21 / 9;
    }
    &.ar-3-1 {
      aspect-ratio: 3 / 1;
    }

    // Landscape
    &.ar-3-4 {
      aspect-ratio: 3 / 4;
    }
    &.ar-9-16 {
      aspect-ratio: 9 / 16;
    }
    &.ar-9-21 {
      aspect-ratio: 9 / 21;
    }
    &.ar-1-3 {
      aspect-ratio: 1 / 3;
    }
  }
}

// Image Elements / Photo Elements
.pageEl.ar-sq {
  .heroPhotoElement {
    aspect-ratio: 1 / 1;
    display: grid;
    place-items: center; 
    overflow: hidden;
    img {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
}
.pageEl.ar-4-3 {
  .heroPhotoElement {
    aspect-ratio: 4 / 3;
    display: grid;
    place-items: center; 
    overflow: hidden;
    img {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
}
.pageEl.ar-16-9 {
  .heroPhotoElement {
    aspect-ratio: 16 / 9;
    display: grid;
    place-items: center; 
    overflow: hidden;
    img {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
}
.pageEl.ar-21-9 {
  .heroPhotoElement {
    aspect-ratio: 21 / 9;
    display: grid;
    place-items: center; 
    overflow: hidden;
    img {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
}
.pageEl.ar-3-4 {
  .heroPhotoElement {
    aspect-ratio: 3 / 4;
    display: grid;
    place-items: center; 
    overflow: hidden;
    img {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
}
.pageEl.ar-9-16 {
  .heroPhotoElement {
    aspect-ratio: 9 / 16;
    display: grid;
    place-items: center; 
    overflow: hidden;
    img {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
}
.pageEl.ar-9-21 {
  .heroPhotoElement {
    aspect-ratio: 9 / 21;
    display: grid;
    place-items: center; 
    overflow: hidden;
    img {
      min-width: 100%;
      min-height: 100%;
      object-fit: cover;
    }
  }
}

.pageEl[class*="ar-"]{
  .heroPhotoElement p.cutline {
    position: absolute;
    bottom:0;
    left:0;
  }
}
