// Variables

:root {
  --premier-content-width: 1200px;

  // customizable base colors
  --site-clr-a: #209acf;
  --site-clr-b: #db1c1d;
  --site-clr-c: #e51fb0;
  --site-clr-d: #1e9945;

  // color variations for light/dark
  --site-clr-al: color-mix(in srgb, var(--site-clr-a) 70%, white);
  --site-clr-ad: color-mix(in srgb, var(--site-clr-a) 80%, black);
  --site-clr-bl: color-mix(in srgb, var(--site-clr-b) 70%, white);
  --site-clr-bd: color-mix(in srgb, var(--site-clr-b) 80%, black);
  --site-clr-cl: color-mix(in srgb, var(--site-clr-c) 70%, white);
  --site-clr-cd: color-mix(in srgb, var(--site-clr-c) 80%, black);
  --site-clr-dl: color-mix(in srgb, var(--site-clr-d) 70%, white);
  --site-clr-dd: color-mix(in srgb, var(--site-clr-d) 80%, black);

  // base colors
  --site-clr-black: #111;
  --site-clr-white: #fff;
  --site-clr-gray-xl: #f7f7f7;
  --site-clr-gray-lt: #e7e7e7;
  --site-clr-gray: #bbb;
  --site-clr-gray-dk: #999;
  --site-clr-gray-xd: #555;
  --site-clr-prm-bg: #f1f1f1;

  // spacing option for padding & margins
  --spacing-x: 0;
  --spacing-xs: 1px;        
  --spacing-sm: 0.3125rem; // 5px
  --spacing-md: 0.625rem;   // 10px
  --spacing-lg: 0.9375rem; // 15px
  --spacing-xl: 1.875rem; // 30px
  --spacing-2x: 3.75rem; // 60px
  --spacing-3x: 5.61rem; // 90px

  // overlay for background images & colors
  --image-bg-ovl-dd: rgba(0, 0, 0, 0.6);
  --image-bg-ovl-dm: rgba(0, 0, 0, 0.3);
  --image-bg-ovl-dl: rgba(0, 0, 0, 0.1);
  --image-bg-ovl-ll: rgba(255, 255, 255, 0.6);
  --image-bg-ovl-lm: rgba(255, 255, 255, 0.3);
  --image-bg-ovl-lx: rgba(255, 255, 255, 0.1);

  // Gradients
  --gradient-top-bottom: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
  --gradient-bottom-top: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));

  // text shadows
  --txt-shd-lt: 0 0 7px #4d4d4d;
  --txt-shd-md: 0 0 7px #282828;
  --txt-shd-dk: 0 0 7px #000000;

  // border radius
  --radius-sm: 3px;
  --radius-md: 5px;
  --radius-lg: 10px;
  --radius-xl: 20px;
  --radius-pill: 25px;

  // Columns
  --mcol-grid-xs: 64px;
  --mcol-grid-sm: 100px;
  --mcol-grid-md: 200px;
  --mcol-grid-lg: 300px;
  --mcol-grid-xl: 500px;

  // Multi column
  --multi-column-gap: 15px;
  --mcol-transition-speed: 0.5s;

  // Split column
  --split-column-gap: 15px;

  // Existing premier site no split containers
  --home-split-ct-gap: 0;
  --home-split-ct-bg: transparent;

  // Existing premier site no split containers
  // --home-split-ct-gap: 15px;
  // --home-split-ct-bg: white;

}
