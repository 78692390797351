// Allows changing the social icons colors bg & symbol color

// Social background color (not the icon color)
.pageEl:has(.sn-social-media-list),
#topNav.topNav .pageEl.social-media-links {
  @each $color in ('a', 'b', 'c', 'd', 'black', 'white', 'gray-xl', 'gray-lt', 'gray', 'gray-dk', 'gray-xd') {
    &.bg-clr-#{$color} {
        background-color: transparent;
      .sn-social-media-list .sn-social-media-icon {
        background-color: var(--site-clr-#{$color});
      }
    }
  }
}

//Social symbol color (thie icon color not the bg color)
.pageEl:has(.sn-social-media-list),
#topNav.topNav .pageEl.social-media-links {
  @each $color in ('a', 'b', 'c', 'd', 'black', 'white', 'gray-xl', 'gray-lt', 'gray', 'gray-dk', 'gray-xd') {
    &.icon-clr-#{$color} {
      .sn-social-media-list .sn-social-media-icon:before {
        color: var(--site-clr-#{$color});
      }
    }
  }
}
