// Buttons

// Classes to create different types of buttons

// Base button styles for all bttn- classes

// Remove button accent idicator from bttn items
.pageEl[class*='bttn-'] .linkElement a {
  padding: 10px 15px;

  &:after {
    display: none;
  }
}

// Display button inline
// .pageEl[class*='bttn-'] {
//   display: inline-block;
//   margin-right: 5px;
// }

.pageEl.bttn-inline-lf {
  .linkElement {
    display:flex;
    justify-content: flex-start;
    h4 {
      text-align: left;
      display:inline-flex;
    }
    a {
      display: inline-block;
    }
  }
}
.pageEl.bttn-inline-ct {
  .linkElement {
    display:flex;
    justify-content: center;
    h4 {
      text-align: center;
      display:inline-flex;
    }
    a {
      display: inline-block;
    }
  }
}
.pageEl.bttn-inline-rt {
  .linkElement {
    display:flex;
    justify-content: flex-end;
    h4 {
      text-align: right;
      display:inline-flex;
    }
    a {
      display: inline-block;
    }
  }
}

// Button background-colors

.pageEl.bg-clr-black:has(.linkElement) {
  background-color: transparent;
  h4 {
    background-color: var(--site-clr-black);
    a {
      background-color: transparent;
      &:after {
        border-left-color: white;
      }
      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}
.pageEl.bg-clr-white:has(.linkElement) {
  background-color: transparent;
  h4 {
    background-color: var(--site-clr-white);
    a {
      color: black;
      background-color: transparent;
      &:after {
        border-left-color: white;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
}
.pageEl.bg-clr-a:has(.linkElement) {
  background-color: transparent;
  h4 {
    background-color: var(--site-clr-a);
    a {
      background-color: transparent;
      &:after {
        border-left-color: white;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
}
.pageEl.bg-clr-b:has(.linkElement) {
  background-color: transparent;
  h4 {
    background-color: var(--site-clr-b);
    a {
      background-color: transparent;
      &:after {
        border-left-color: white;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
}
.pageEl.bg-clr-c:has(.linkElement) {
  background-color: transparent;
  h4 {
    background-color: var(--site-clr-c);
    a {
      background-color: transparent;
      &:after {
        border-left-color: white;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
}
.pageEl.bg-clr-d:has(.linkElement) {
  background-color: transparent;
  h4 {
    background-color: var(--site-clr-d);
    a {
      background-color: transparent;
      &:after {
        border-left-color: white;
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }
    }
  }
}


// Button Groups
// This requires the createButtonGroup js function to make these groups

.user_mode .bttn-group {
  display: flex;
  flex-flow: row wrap;
  gap: 10px;

  &:has(.pageEl.bttn-inline-lf) {
    justify-content: flex-start;
  }

  &:has(.pageEl.bttn-inline-ct) {
    justify-content: center;
  }
  &:has(.pageEl.bttn-inline-rt) {
    justify-content: flex-end;
  }
}
